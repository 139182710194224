//export const DATA_URL = `/data`;
let isProd = true;
export const BASE_URL = isProd ?"https://unlutekstil.com.tr" : "http://ssrunlutekstilv2.gulenayva.com";
//export const BASE_URL = "http://ssrunlutekstilv2.gulenayva.com";
export const DATA_URL = typeof window === "undefined" ? (BASE_URL+"/data") : ("/data");
export const ASSETS = `/assets`;
export const SUSTAINABILITY_ASSETS = `http://sustainability.unlutekstil.com.tr/assets`;
export const ACADEMY_ASSETS = `http://akademi.unlutekstil.com.tr/assets`;
//export const API_URL = process.env.NODE_ENV !== 'production' ? "http://localhost:5000/web/":window.location.origin+"/back/web/";
export const API_URL = process.env.NODE_ENV === 'development'  ? "http://localhost:5000/web/": (typeof window === "undefined" ? BASE_URL+"/back/web/":window.location.origin+"/back/web/") ;
export const RECAPTCHA_SITE_KEY = "6LcmmD4UAAAAAI4sblQ-Z7-fUVAqclsw0dtvMM24";
