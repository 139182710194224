import React, {Component} from 'react';
import KnowledgeComponent from "../../../components/home/knowledge/knowledgeComponent";
import InfinitySliderTextComponent from "../../../components/common/infinitySliderTextComponent";
import {connect} from "react-redux";
import {addCursorClass, removeCursorClass} from "../../../helpers";


class KnowledgeContainer extends Component {
    /*onEnter = () => {
        addCursorClass();
    }
    onLeave = () => {
        removeCursorClass()
    }

    componentWillUnmount() {
        removeCursorClass()
    }*/

    render() {
        const {knowledgeData,academyEducations,sustainabilityEducations} = this.props;
        return (
            <section className="knowledge-root" onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                {
                    knowledgeData.infinity && knowledgeData.infinity.length>0 && knowledgeData.infinity.map((item,key) =>
                        <InfinitySliderTextComponent key={key} {...item}/>
                    )
                }

                <div className="knowledge-container">
                    {
                         knowledgeData.items && knowledgeData.items.length>0 && knowledgeData.items.map((item,key) =>
                            <KnowledgeComponent index={key} key={key} {...item} educations={key===0?academyEducations:sustainabilityEducations}/>
                        )
                    }

                </div>
            </section>
        );
    }
}


const mapStateToProps = (state) => ({
    knowledgeData: state.homeReducer[state.langReducer.activeLang] ? state.homeReducer[state.langReducer.activeLang].knowledgeData : [] ,
    academyEducations: state.homeReducer.academyEducations,
    sustainabilityEducations: state.homeReducer.sustainabilityEducations,
});

export default connect(mapStateToProps)(KnowledgeContainer);
